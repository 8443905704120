import { Action } from '@ngrx/store';

export const GET_LANGUAGE = '[language] get';
export const GET_LANGUAGE_SUCCESS = '[language] get success';
export const LANGUAGE_CHANGE = '[language] changed';
export const LANGUAGE_CHANGE_SUCCESS = '[language] changed success';
export const LANGUAGE_CHANGE_FAIL = '[language] changed fail';

export class LanguageChange implements Action {
  readonly type = LANGUAGE_CHANGE;

  constructor(public payload: any = null) {}
}

export class LanguageChangeSuccess implements Action {
  readonly type = LANGUAGE_CHANGE_SUCCESS;

  constructor(public payload: any = null) {}
}

export class LanguageChangeFail implements Action {
  readonly type = LANGUAGE_CHANGE_FAIL;

  constructor(public payload: any = null) {}
}

export class GetLanguage implements Action {
  readonly type = GET_LANGUAGE;

  constructor(public payload: any = null) {}
}

export class GetLanguageSuccess implements Action {
  readonly type = GET_LANGUAGE_SUCCESS;

  constructor(public payload: any = null) {}
}

export type Actions =
  | GetLanguage
  | GetLanguageSuccess
  | LanguageChange
  | LanguageChangeSuccess
  | LanguageChangeFail;
