// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentInterface } from './environment-interface';

export const environment = <EnvironmentInterface>{
  production: false,
  environmentName: 'Develop Environment',
  facebookClientId: '1109212779284547',
  googleClientId: '811527235166-cv67ogp13dmrmm9a61en2tb0s2piburj.apps.googleusercontent.com',
};

export enum EndPoints {
  feedback = 'call-back',
  venue = 'event/client/filter-global-client',
  registration = 'user/client/registration',
  resetPassword = 'user/client/reset-password',
  resetPasswordConfirm = 'user/client/reset-password-confirm',
  logIn = 'oauth/token',
  logInWithFacebook = '',
  logInWithGoogle= '',
  categories = 'category/active/find-all',
  category = 'category/find-one',
  user = 'user/get',
  actualTickets = 'ticket/client/find-all-actual-bin',
  archiveTickets = 'ticket/client/find-all-archive-bin',
  // reservationTickets = '/user/find-all-booked-bin-pageable/true',
  reservationTickets = 'user/find-all-booked-bin/true',
  deleteReservationTickets = 'v1/client/delete-booked-bin',
  buyReservationTickets = 'v1/client/order-booked-buy-bin',
  wishList = 'user/find-like-event',
  invite = 'ticket/client/find-all-invite-bin',
  getEvent = 'event/client/find-one-public-event-parse-client-event-one-base-data-dto',
  emailNotificationsCheck = 'user/check-notification-email',
  emailNotifications = 'user/add-notification-email',
  updateUser = 'user/user/update-user',
  updatePassword = 'user/change-password',
  setToWishList = 'user/like/id-event',
  getBarcode = 'ticket/client/find-barcode',
  getCities = 'location/active/find-all-city',
  getGenres = 'genre/active/find-all',
  getHallPlaces = 'hall/client/find-all-platform-hall-available',
  getPlatformHallsByCityAndCategory = 'hall/client/find-available-platform-halls-by-city-and-category',
  getPlatformHallsByCityCategory = 'hall/client/find-available-platform-halls-by-city-and-category',
  getPlatformHalls = 'hall/client/find-all-platform-hall-available',
  getPlatformHall = 'hall/find-one-platform-hall',
  updateUserPhoto = 'user/update-image',
  searchEvents = 'event/client/find-public-event-researcher-client-prev-event-base-data-dto',
  promoCode = 'promo-code/client/valid',
  destroyBin = 'bin/delete',
}

export const API_URL = 'https://z.test.soldout.com.ua/main-service/';
// export const API_URL = 'https://z.soldout.com.ua/main-service/';

export const IMAGES_URL = 'https://z.test.soldout.com.ua/image-service/image-service/file/';
// export const IMAGES_URL = 'https://z.soldout.com.ua/image-service/image-service/file/';

export const WS_URL = 'https://z.test.soldout.com.ua/web-socket-service/web-socket-service/ws-socket';
// export const WS_URL = 'https://z.soldout.com.ua/web-socket-service/web-socket-service/ws-socket';


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
