import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { BookingActions, BookingActionTypes, BookingFail, BookingSuccess, GetBin, GetBinSuccess, GetPrice, GetPriceFail, GetPriceSuccess } from '@app/+store/booking/booking.actions';
import { BookingService } from '@app/services/booking.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '@app/services';
import { SnackBarService } from '@app/services/snack-bar.service';
import { getEvent } from '@app/+store/event/event.selector';
import { mergeMap, withLatestFrom, tap } from 'rxjs/internal/operators';
import { IAppState } from '@app/+store';
import { Store } from '@ngrx/store';
import { EventModel } from '@app/models/event.model';
import { BookingModel } from '@app/models/booking.model';
import { BookedSeatModel } from '@app/models/booked-seat.model';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class BookingEffects {

  constructor(private actions$: Actions,
    private bookingService: BookingService,
    private commonService: CommonService,
    private snackBarService: SnackBarService,
    private store: Store<IAppState>,
    private translateService: TranslateService,
  ) { }

  @Effect() getBin$ = this.actions$.pipe(
    ofType(BookingActionTypes.GetBin),
    withLatestFrom(this.store.select(getEvent)),
    map(([action, event]: [BookingActions, EventModel]) => ({ orderId: action.payload, eventId: event ? event.id : null })),
    switchMap(({ orderId, eventId }: { orderId: string, eventId: number }) => this.bookingService.getBin(orderId)
      .pipe(
        mergeMap((bin: BookedSeatModel[]) => {
          if (!bin.length) {
            // this.commonService.removeOrderFromLocalStorage(eventId);
          }
          // return [new GetBinSuccess(bin), new GetPrice(orderId)]; double request for get price
          return [new GetBinSuccess(bin)];
        }),
        catchError((err: HttpErrorResponse) => {
          this.commonService.removeOrderFromLocalStorage(eventId);

          if (err.status === 406) {
            this.translateService.get('ticketsNotAvailable').subscribe(text => {
              this.snackBarService.showSnackBar(text);
            });
            // this.snackBarService.showSnackBar('Квитки не доступні до продажу!');
          }

          if (err.status === 410) {
            if (eventId) {
            }
            this.translateService.get('basketTimeIsUp').subscribe(text => {
              this.snackBarService.showSnackBar(text);
            });
            this.commonService.removeOrderFromLocalStorage(eventId);
            // this.snackBarService.showSnackBar('Час корзини минув, попробуйте ще раз!');
            return of(new GetBinSuccess([]));
          }

          console.error(err.status, err);
        }),
      ),
    ),
  );

  @Effect()
  getPrice$ = this.actions$.pipe(
    ofType(BookingActionTypes.GetPrice),
    map((action: BookingActions) => action.payload),
    switchMap((order) => this.bookingService.getPrice(order)
      .pipe(
        map((price) => new GetPriceSuccess(price)),
        catchError((err: HttpErrorResponse) => of(new GetPriceFail(err))),
      )),
  );

  @Effect()
  booking$ = this.actions$.pipe(
    ofType(BookingActionTypes.Booking),
    map((action: BookingActions) => action.payload),
    tap((val) => {
      this.manageCountTicketsInBin(val, val.comandBooking === 'LOCK');
    }),
    switchMap((body: BookingModel) => this.bookingService.booking(body)
      .pipe(
        mergeMap((bookedSeat: BookedSeatModel) => [new BookingSuccess({ comandBooking: body.comandBooking, bookedSeat }), new GetPrice(body.order)]),
        catchError((err: HttpErrorResponse) => {
          this.store.dispatch(new GetBin(body.order));
          this.store.dispatch(new GetPrice(body.order));
          if (body.comandBooking === 'LOCK') {
            this.manageCountTicketsInBin(body, false);
          }
          if (err.status === 409) {
            this.translateService.get('ticketIsLockAnotherUser').subscribe(text => {
              this.snackBarService.showSnackBar(text);
            });
            // this.snackBarService.showSnackBar('Цей квиток зайнятий іншим користувачем, спробуйте інший!');
          } else if (err.status === 423 || err.status === 410) {
            this.translateService.get('ticketIsLock').subscribe(text => {
              this.snackBarService.showSnackBar(text);
            });
            // this.snackBarService.showSnackBar('Квиток заблоковано!');
          } else if (err.status === 416) {
            this.translateService.get('tooTicketsInBasket').subscribe(text => {
              this.snackBarService.showSnackBar(text);
            });
            // this.snackBarService.showSnackBar('Забагато квитків в корзині!');
          } else {
            this.translateService.get('errorDefault').subscribe(text => {
              this.snackBarService.showSnackBar(text);
            });
            // this.snackBarService.showSnackBar('error');
          }

          console.error(err.status, err);
          return of(new BookingFail(err));
        }),
      )),

  );

  manageCountTicketsInBin(body, isInc = true) {
    let ordersInLocalStorage = JSON.parse(this.commonService.getFromLocalStorage('orders'));
    if (!ordersInLocalStorage) {
      return;
    }
    const currentOrder = ordersInLocalStorage.find(val => val.order + '' === body.order + '');
    if (!currentOrder) {
      return;
    }
    if (!currentOrder.tickets) {
      currentOrder.tickets = 0;
    }
    if (isInc) {
      currentOrder.tickets = +currentOrder.tickets + 1;
    } else {
      currentOrder.tickets = +currentOrder.tickets - 1;
    }
    if (currentOrder.tickets === 0) {
      ordersInLocalStorage = ordersInLocalStorage.filter(v => v.order !== body.order);
      if (!ordersInLocalStorage.length) {
        this.commonService.removeOrderFromLocalStorage(body.idEvent);
        return;
      }
    }
    this.commonService.setToLocalStorage('orders', JSON.stringify(ordersInLocalStorage), true);
  }

}

