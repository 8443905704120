import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-error-display',
    templateUrl: './error-display.html',
    styleUrls: ['./error-display.scss'],
})
export class ErrorDisplayComponent {
    @Input() errorMsg: string;
}
