import { EventActionTypes, EventActions } from './event.actions';
import { initialEventState } from '@app/+store/event/event.state';


export function eventReducer(state = initialEventState, { type, payload }: EventActions) {
  switch (type) {
    case EventActionTypes.GetEvent:
      return state;
    case EventActionTypes.GetEventSuccess:
      return {
        ...state,
        event: payload,
      };
    case EventActionTypes.GetEventFail:
    default:
      return state;
  }
}
