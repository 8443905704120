import { BookingActions, BookingActionTypes } from './booking.actions';
import { BookingState, initialBookingState } from '@app/+store/booking/booking.state';
import { BookedSeatModel } from '@app/models/booked-seat.model';
import { CommandBooking } from '@app/enums/command-booking.enum';

export function bookingReducer(state = initialBookingState, { type, payload }: BookingActions) {
  switch (type) {
    case BookingActionTypes.GetBinSuccess:
      return {
        ...state,
        bin: payload,
      };
    case BookingActionTypes.GetBinFail:
      return {
        ...state,
        bin: [],
      };
    case BookingActionTypes.BookingSuccess:
      return bookingSuccess(state, payload);
    case BookingActionTypes.GetPriceSuccess:
      return {
        ...state,
        price: payload,
      };
    case BookingActionTypes.CreateBin:
    case BookingActionTypes.CreateBinSuccess:
    case BookingActionTypes.CreateBinFail:
    case BookingActionTypes.GetPrice:
    case BookingActionTypes.GetPriceFail:
    case BookingActionTypes.Booking:
    case BookingActionTypes.BookingFail:
    default:
      return state;
  }
}

function bookingSuccess(state, { comandBooking, bookedSeat }: { comandBooking: CommandBooking, bookedSeat: BookedSeatModel }): BookingState {
  let bin: BookedSeatModel[];

  if (CommandBooking.LOCK === comandBooking) {
    bin = [bookedSeat, ...state.bin];
  } else {
    bin = state.bin.filter((seat: BookedSeatModel) => seat.id !== bookedSeat.id);
  }

  return { ...state, bin };
}
