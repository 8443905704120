import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-timer-timeout-modal',
  templateUrl: './timer-timeout-modal.component.html',
  styleUrls: ['./timer-timeout-modal.component.scss'],
})
export class TimerTimeoutModalComponent {

  constructor(public dialogRef: MatDialogRef<TimerTimeoutModalComponent>) {}

  closeModal(isGoAway = true): void {
    event.stopPropagation();
    event.preventDefault();
    this.dialogRef.close(isGoAway);
  }

}
