import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class LoaderService {

  private _isLoading: Subject<boolean> = new Subject<boolean>();
  isLoading$: Observable<boolean> = this._isLoading.asObservable();

  private _isLoadingBack: Subject<boolean> = new Subject<boolean>();
  isLoadingBack$: Observable<boolean> = this._isLoadingBack.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.modifyLoaderBackend(false);
    this.modifyLoader(false);
  }


  modifyLoader(state: boolean) {
    if (state) {
      if (isPlatformBrowser(this.platformId)) {
        this._isLoading.next(state);
      }
    } else {
      this._isLoading.next(state);
    }
  }

  modifyLoaderBackend(state: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      this._isLoadingBack.next(state);
    }
  }
}
