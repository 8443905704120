import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {appLocaleConfig} from '@app/app.settings';

// import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export function TranslateHttpLoaderFactory(httpClient: HttpClient, platformId) {

  class AmwTranslateHttpLoader extends TranslateHttpLoader {
    constructor(_httpClient: HttpClient, prefix: string, suffix: string) {
      super(_httpClient, prefix, suffix);
    }

    getTranslation(lang: string): Observable<any> {
      return super.getTranslation(lang).pipe(
        catchError(error => of(error)), // the only way to handle the http error within ngx-translate
      );
    }
  }

  return new AmwTranslateHttpLoader(httpClient, appLocaleConfig.assets.prefix, appLocaleConfig.assets.suffix);
}
