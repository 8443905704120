import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventState } from '@app/+store/event/event.state';



export const getEventState = createFeatureSelector<EventState>('event');

export const getEvent = createSelector(
  getEventState,
  (state: EventState) => state.event,
);
