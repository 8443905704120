/* tslint:disable */
export const objToUrlParams = (obj) => {
  let str = '?';
  for (const key in obj) {
    if (str !== '?') {
      str += '&';
    }
    str += key + '=' + obj[key];
  }
  return str;
};


