import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root', // TODO: detect how can we use it only  with interceptor & spinner component(ngrx store)
})
export class LoadingSpinnerService {
  private spinnerTrigger: BehaviorSubject<boolean> = new BehaviorSubject(false);
  spinnerTrigger$ = this.spinnerTrigger.asObservable();

  show(): void {
    this.spinnerTrigger.next(true);
  }

  hide(): void {
    this.spinnerTrigger.next(false);
  }
}
