import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { EventService } from '@app/services';
import { EventActions, EventActionTypes, GetEventSuccess, GetEventFail } from '@app/+store/event/event.actions';
import { EventModel } from '@app/models/event.model';

@Injectable()
export class EventEffects {
  @Effect()
  getEvent$ = this.actions$.pipe(
    ofType(EventActionTypes.GetEvent),
    map((action: EventActions) => {
      return action.payload;
    }),
    switchMap( (id) => this.eventService.getEvent(id)
      .pipe(
        map((event: EventModel) => new GetEventSuccess(event)),
        catchError(err => of(new GetEventFail(err))),
      )),
  );

  constructor(private actions$: Actions,
              private eventService: EventService) {}

}

