import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {LanguageModel} from '@app/models/language.model';
import {languages} from '@app/app.settings';
import {IAppState} from '@app/+store';
import {select, Store} from '@ngrx/store';
import {getLanguageState} from '@app/+store/language/language.selectors';
import {map, tap} from 'rxjs/internal/operators';
import {Observable, Subscription} from 'rxjs';
import {Router, NavigationEnd} from '@angular/router';
import {CommonService} from '@app/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit, OnDestroy {

  homeHeader = false;
  languages: LanguageModel[] = languages;
  currentLang$: Observable<string>;
  searchSub: Subscription;
  routSub: Subscription;
  removeHeader: boolean;
  isCabinetPage = false;
  homeSearch: any;
  lang;
  isTransparentHeader = true;

  constructor(private store$: Store<IAppState>,
              private router: Router,
              private commonService: CommonService,
              private renderer: Renderer2,
  ) {
  }

  loadCurrentOptionByUrl(url) {
    this.renderer.removeClass(document.body, 'body-overflow');
    this.removeHeader = false;
    this.homeSearch = false;
    this.homeHeader = false;
    this.isCabinetPage = false;
    this.isTransparentHeader = false;
  }

  ngOnInit() {
    this.loadCurrentOptionByUrl(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loadCurrentOptionByUrl(event.url);
      }
    });

    this.currentLang$ = this.store$.pipe(
      select(getLanguageState),
      map((lang: LanguageModel) => lang.id),
      tap(langId => {
        this.lang = langId;
      }),
    );
  }

  ngOnDestroy(): void {
    this.commonService.unsubscribeAll([this.searchSub, this.routSub]);
    this.renderer.removeClass(document.body, 'body-overflow');
  }

  reloadPage() {
    event.stopPropagation();
    window.location.reload();
  }
}
