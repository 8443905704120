import {BrowserModule} from '@angular/platform-browser';
import {NgModule, PLATFORM_ID} from '@angular/core';

import {AppComponent} from './app.component';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {appRoutes} from './app.routes';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoaderFactory} from '@app/shared';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {EFFECTS, metaReducers, reducers} from '@app/+store';
import {LoadingSpinnerComponent} from '@app/shared/components/loading-spinner/loading-spinner.component';
import {MatSnackBarModule} from '@angular/material';
import {SharedModule} from '@app/shared/shared.module';
import {TransferHttpCacheModule} from '@nguniversal/common';

@NgModule({
  declarations: [
    AppComponent,
    LoadingSpinnerComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({appId: 'SoldOut'}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient, PLATFORM_ID],
      },
      useDefaultLang: false,
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([
      ...EFFECTS,
    ]),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50}) : [],
    RouterModule.forRoot(
      appRoutes,
      {
        useHash: false,
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'top',
        initialNavigation: 'enabled',
      },
    ),
    MatSnackBarModule, // TODO: FIX remove in shared module(now dont want work from shared)
    SharedModule, // TODO: refectore to core module and remove shared from this module
    TransferHttpCacheModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
