import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerService } from '@app/shared/components/loading-spinner/loading-spinner.service';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
   spinnerTrigger$;

  constructor(private loadingSpinnerService: LoadingSpinnerService) {
  }

  ngOnInit(): void {
    this.spinnerTrigger$ = this.loadingSpinnerService.spinnerTrigger$;
  }
}
