import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgUrlPipe } from '@app/shared/pipes/img-url.pipe';
import { MaterialModule } from '@app/shared/material/material.module';
import { AppTranslatePipe } from '@app/shared/pipes/app-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgxBarcodeModule } from 'ngx-barcode';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { SafeHtmlPipe } from '@app/shared/pipes/safe-html.pipe';
import { TimerComponent } from './components/timer/timer.component';
import { TextMaskModule } from 'angular2-text-mask';
import { TimerTimeoutModalComponent } from '@app/shared/components/modals/timer-timeout-modal/timer-timeout-modal.component';
import { SafeUrlPipe } from '@app/shared/pipes/safe-url.pipe';
import { MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatListModule, MatStepperModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { ButtonScrollToTopComponent } from './components/button-scroll-to-top/button-scroll-to-top.component';
import { ErrorDisplayComponent } from '@app/shared/components/error-validation/error-display.component';
import { ExitModalComponent } from './components/modals/exit-modal/exit-modal.component';
import { WordConvert } from './pipes/word-convert.pipe';
import { BookingPaymentComponent } from './components/booking-payment/booking-payment.component';

const SHARED_COMPONENTS = [
  HeaderComponent,
  TimerComponent,
  ButtonScrollToTopComponent,
  ErrorDisplayComponent,
  BookingPaymentComponent,
];

const SHARED_ENTRY_COMPONENTS = [
  TimerTimeoutModalComponent,
  ExitModalComponent,
];

const SHARED_MODULES = [
  CommonModule,
  MaterialModule,
  TranslateModule,
  NgxBarcodeModule,
  ReactiveFormsModule,
  RouterModule,
  FormsModule,
  TextMaskModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatListModule,
  MatCheckboxModule,
  MatButtonModule,
  MatExpansionModule,
];
const SHARED_PIPES = [
  ImgUrlPipe,
  AppTranslatePipe,
  SafeHtmlPipe,
  SafeUrlPipe,
  WordConvert,
];

@NgModule({
  declarations: [
    ...SHARED_COMPONENTS,
    ...SHARED_ENTRY_COMPONENTS,
    ...SHARED_PIPES,
    ExitModalComponent,
  ],
  entryComponents: [
    ...SHARED_ENTRY_COMPONENTS,
  ],
  imports: [
    ...SHARED_MODULES,
    MatCheckboxModule,
    MatStepperModule,
  ],
  providers: [

  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    ...SHARED_COMPONENTS,
    ...SHARED_MODULES,
    ...SHARED_PIPES,
  ],
})
export class SharedModule {

}
