import {
  Actions,
  LANGUAGE_CHANGE,
  LANGUAGE_CHANGE_SUCCESS,
  LANGUAGE_CHANGE_FAIL,
  GET_LANGUAGE,
  GET_LANGUAGE_SUCCESS,
} from './language.actions';
import { LanguageModel } from '@app/models/language.model';
import { DEFAULT_LANGUAGE } from '@app/app.settings';

const initialState: LanguageModel = { ...DEFAULT_LANGUAGE };

export function languageReducer(state = initialState, { type, payload }: Actions): LanguageModel {
  switch (type) {
    case GET_LANGUAGE:
    case GET_LANGUAGE_SUCCESS:
    case LANGUAGE_CHANGE:
    case LANGUAGE_CHANGE_SUCCESS:
    case LANGUAGE_CHANGE_FAIL:
      return { ...state, ...payload };
    default:
      return state;
  }
}
