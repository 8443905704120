import { Injectable } from '@angular/core';
import { Subscriber, Subscription } from 'rxjs';
export const ORDER_KEY = 'ORDER_';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  private localStorage = localStorage;
  private sessionStorage = sessionStorage;

  unsubscribeAll(...args): void {
    const subscriptions = Array.isArray(args[0]) ? args[0] : args;

    subscriptions
      .filter(Boolean)
      .forEach((sub) => {
        if (!(sub instanceof Subscription) && !(sub instanceof Subscriber)) {
          return;
        }
        sub.unsubscribe();
      });
  }

  setToLocalStorage(name: string, value: string, localStorage: boolean): void {
    if (localStorage) {
      this.localStorage.setItem(name, value);
      return;
    }
    this.sessionStorage.setItem(name, value);
  }

  removeFromLocalStorage(name: string): void {
    this.localStorage.removeItem(name);
  }

  removeFromSessionStorage(name: string): void {
    this.sessionStorage.removeItem(name);
  }

  getFromLocalStorage(key: string): string {
    return this.localStorage.getItem(key);
  }

  getFromSessionStorage(key: string): string {
    return this.sessionStorage.getItem(key);
  }

  parserFromStorage(value: string): any {
    return JSON.parse(this.localStorage.getItem(value));
  }

  getAllOrdersFromLS(): any[] {
    return JSON.parse(this.localStorage.getItem('orders'));
  }

  getOrderFromLocalStorage(eventId: number): string {
    const ordersArray = JSON.parse(this.localStorage.getItem('orders')) || [];
    const currentOrder = ordersArray.find(val => +(val.id) === +eventId);
    return currentOrder ? currentOrder.order : null;
  }
  getOrderTimeFromLocalStorage(eventId: number): string {
    const ordersArray = JSON.parse(this.localStorage.getItem('orders')) || [];
    const currentOrder = ordersArray.find(val => +(val.id) === +eventId);
    return currentOrder ? currentOrder.orderEndTime : null;
  }

  setOrderToLocalStorage(eventId: number, value: string, n: any, time: any): void {
    const parseToStringEnd = new Date(new Date().getTime() + (time * 1000)).toString();
    const ordersArray = JSON.parse(this.localStorage.getItem('orders')) || [];
    const newBasket: any = { id: eventId, order: value, orderEndTime: parseToStringEnd, name: n };
    ordersArray.push(newBasket);
    this.localStorage.setItem('orders', JSON.stringify(ordersArray));
  }

  removeOrderFromLocalStorage(eventId: number): void {
    let ordersArray = JSON.parse(this.localStorage.getItem('orders')) || [];
    if (!ordersArray) {
      return;
    }
    ordersArray = ordersArray.filter(val => +(val.id) !== +eventId);
    if (ordersArray.length) {
      this.localStorage.setItem('orders', JSON.stringify(ordersArray));
    } else {
      this.localStorage.removeItem('orders');
    }
  }



}
