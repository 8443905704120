import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-exit-modal',
  templateUrl: './exit-modal.component.html',
  styleUrls: ['./exit-modal.component.scss'],
})
export class ExitModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExitModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: 'booking' | 'default' = 'default' ) { }

  ngOnInit() {
  }

  close(accept?) {
    this.dialogRef.close(accept ? accept : null);
  }

}
