import { Pipe, PipeTransform } from '@angular/core';
import { TranslateValueModel } from '@app/models/translate-value.model';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@app/+store';
import { LanguageModel } from '@app/models/language.model';
import { DEFAULT_LANGUAGE, LanguageIds } from '@app/app.settings';
import { getLanguageState } from '@app/+store/language/language.selectors';

@Pipe({
  name: 'appTranslate',
  pure: false,
})
export class AppTranslatePipe implements PipeTransform {
  language: LanguageModel;

  constructor(store: Store<IAppState>) {
    store.pipe(select(getLanguageState)).subscribe( (language: LanguageModel) => this.language = language);
  }

  transform(value: TranslateValueModel): any {
    if (!value) {
      return '';
    }
    return this.getValue(value);
  }

  private getValue(value: TranslateValueModel) {
    const languageId = this.language && this.language.id || DEFAULT_LANGUAGE.id;
    switch (languageId) {
      case LanguageIds.En:
        return value.valueEN;
      case LanguageIds.Ru:
        return value.valueRU;
      case LanguageIds.Ua:
        return value.valueUA;
    }
  }
}
