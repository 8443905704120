import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { CommonService } from '@app/services';
import { map } from 'rxjs/internal/operators';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() timerTo: string;
  @Input() eventId?: number;
  @Input() isEventBookingTimer?: boolean;
  @Output() finished: EventEmitter<void> = new EventEmitter<void>();
  public timer: string;
  public timerSec: number;
  private future;
  private subscription: Subscription;
  constructor(private commonService: CommonService) {}

  initTimer() {
    if (this.commonService.getOrderTimeFromLocalStorage(this.eventId) && this.isEventBookingTimer ) {
      this.timerTo = this.commonService.getOrderTimeFromLocalStorage(this.eventId);
    }
    this.startTimer(this.timerTo);
  }

  ngOnInit() {
    this.initTimer();
  }

  ngOnChanges() {
    this.startTimer(this.timerTo);
  }

  ngOnDestroy(): void {
    this.commonService.unsubscribeAll([this.subscription]);
  }

  private startTimer(time: string) {
    this.future = new Date(time);
    // alert(this.future);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = interval()
      .pipe(
        map(() => Math.floor((this.future.getTime() - new Date().getTime()) / 1000)),
      )
      .subscribe((sec) => {
        this.timer = this.format(sec);
        this.timerSec = sec;
        if (sec <= 0) {
          this.finished.emit();
          this.subscription.unsubscribe();
        }
      });
  }

  private format(t) {
    let days, hours, minutes, seconds;
    days = Math.floor(t / 86400);
    t -= days * 86400;
    hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    seconds = t % 60;

    return[days, hours]
      .filter(n => !!n)
      .concat(minutes, seconds)
      .map(n => n === 0 ? '00' : n < 10 ? `0${n}` : n)
      .join(':');
  }
}


