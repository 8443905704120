import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppState} from '@app/+store';
import {LanguageChange} from '@app/+store/language/language.actions';
import {Subscription} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  storeSub = new Subscription();
  isLoader = false;
  isLoaderBack = false;

  lang;

  constructor(private store$: Store<IAppState>,
              private loaderService: LoaderService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.loaderService.isLoading$.subscribe(next => {
      setTimeout(() => {
        this.isLoader = next;
      });
    });
    this.loaderService.isLoadingBack$.subscribe(next => {
      setTimeout(() => {
        this.isLoaderBack = next;
      });
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.lang) {
        this.lang = params.lang;
      } else {
        this.lang = 'ua';
      }
      const l = (this.lang.toLowerCase() === 'ua') ? 'uk' : this.lang.toLowerCase();
      document.documentElement.setAttribute('lang', l);
      this.store$.dispatch(new LanguageChange({id: this.lang}));
    });
  }

  ngOnDestroy(): void {
    this.storeSub.unsubscribe();
  }

  reloadPage() {
    window.location.reload();
  }

}
