import { EventModel } from '@app/models/event.model';

export interface EventState {
  event: EventModel;
}

export const initialEventState: EventState = {
  event: null,
};

