import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatMenuModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
  MatRadioModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  DragDropModule,
];

@NgModule({
  declarations: [],
  imports: [
    ...MATERIAL_MODULES,
  ],
  exports: [
    ...MATERIAL_MODULES,
  ],
})
export class MaterialModule {}
