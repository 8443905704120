import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordConvert',
  pure: false,
})
export class WordConvert implements PipeTransform {

  transform(value: { type: 'tickets', value: any }, args?: any): any {
    if (value.type === 'tickets') {
      if (value.value === 1) {
        return 'ticket1';
      } else if (value.value > 1 && value.value < 5) {
        return 'tickets2';
      } else {
        return 'tikets';
      }
    }
  }

}
