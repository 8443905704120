import { BookedSeatModel } from '@app/models/booked-seat.model';
import { PriceModel } from '@app/models/price.model';

export interface BookingState {
  bin: BookedSeatModel[];
  price: PriceModel;
}

export const initialBookingState: BookingState = {
  bin: [],
  price: null,
};
