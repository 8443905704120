import { Action } from '@ngrx/store';
import { EventModel } from '@app/models/event.model';

export enum EventActionTypes {
  GetEvent = '[Event] Get Event',
  GetEventSuccess = '[Event] Get Event Success',
  GetEventFail = '[Event] Get Event Fail',
}

export class GetEvent implements Action {
  readonly type = EventActionTypes.GetEvent;

  constructor(public payload: number = null) {}
}

export class GetEventSuccess implements Action {
  readonly type = EventActionTypes.GetEventSuccess;

  constructor(public payload: EventModel = null) {
  }
}

export class GetEventFail implements Action {
  readonly type = EventActionTypes.GetEventFail;

  constructor(public payload: any = null) {
  }
}

export type EventActions =
  | GetEvent
  | GetEventSuccess
  | GetEventFail;
