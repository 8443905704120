import { Component, OnInit, HostListener } from '@angular/core';

@Component({
    selector: 'app-button-scroll-to-top',
    templateUrl: './button-scroll-to-top.component.html',
    styleUrls: ['./button-scroll-to-top.component.scss'],
})
export class ButtonScrollToTopComponent implements OnInit {

    showScroll: boolean;
    showScrollHeight = 300;
    hideScrollHeight = 10;

    constructor() {
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
            this.showScroll = true;
        } else if (this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
            this.showScroll = false;
        }
    }

    ngOnInit(): void {

    }

    scrollToTop() {
        (function smoothscroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 5));
            }
        })();
    }
}
