import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {API_URL, EndPoints} from '../../environments/environment';
import {Observable, throwError, of} from 'rxjs';
import {BookingModel} from '@app/models/booking.model';
import {objToUrlParams} from '@app/utilities/http.utilities';
import {BookedSeatModel} from '@app/models/booked-seat.model';
import {PriceModel} from '@app/models/price.model';
import {BookedOrderModel} from '@app/models/booked-order.model';
import {catchError} from 'rxjs/operators';
import {CommonService} from '@app/services/common.service';
import {SnackBarService} from '@app/services/snack-bar.service';
import {isPlatformBrowser} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

interface User {
  firstName: string;
  lastName: string;
  mail: string;
  phone: string;
}

@Injectable({
  providedIn: 'root',
})
export class BookingService {

  constructor(private httpClient: HttpClient, private commonService: CommonService,
              private snackBarService: SnackBarService, @Inject(PLATFORM_ID) private platformId: Object,
              private translateService: TranslateService) {
  }

  /*
    This controller validate the promo code. If status is 404 it means that the entered one doesn't exist.
    RequestParam - String code - Promo Code;
    PathVariable Long idEvent - Event ID (here is for event with ID: 5)
  */

  createBin(eventId: number): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const requestOptions: Object = {
      headers,
      responseType: 'text',
    };
    const obj = {id: eventId};
    return this.httpClient.post<string>(`${API_URL}v1/client/create-bin${objToUrlParams(obj)}`, {}, requestOptions);
  }

  /*
    Add tickets into the Bin.
    RequestBody BookingModel;
    Long idSeat;
    Long idTicket;
    Long idEvent;
    String order;
    CommandBooking comandBooking; - Enum: LOCK, UN_LOCK;
    TypeOfScheme typeOfScheme; - Enum: WITH_SCHEME,WITHOUT_SCHEME;
    Example:
      comandBooking: "LOCK" idEvent: "5" idSeat: 1777 idTicket: 4587 order: "000000000005271" typeOfScheme: "WITH_SCHEME"
      */

  booking(body: BookingModel): Observable<BookedSeatModel> {
    return this.httpClient.post<BookedSeatModel>(`${API_URL}v1/client/booking`, body);
  }

  /*
    Returns price for ticket.
    PathVariable String order;
    Example:
    bookedCommission: null bookedCommissionPrice: null commission: 0.01 price: 112.11 priceWithOutCommission: 111
   */

  getPrice(order: string): Observable<any> {
    if (order) {
      return this.httpClient.get<PriceModel>(`${API_URL}v1/client/price/${order}`);
    } else {
      return of(false);
    }
  }

  /*
Use for pay by card for tickets in the current Bin.
Path variable using for changing language (Possible languages: RU,EN,UK;)
RequestBody - BinOrderRequestDto
UserOrderRequestDto user;
String order;
String comment;
If email isn't correct it causes a NotValidEmailExceptions in spite of other data in the request.
Example:
comment: "" order: "000000000005272" ticketList: [] user: {firstName: "Андрій", lastName: "Андрій",
 mail: "andriybalian98@gmail.com",…} firstName: "Андрій" lastName: "Андрій" mail: "andriybalian98@gmail.com" phone: "38 (806)-48-72-458"
 */

  orderBuy(body: { user: User, order: string, comment: string }, language: 'RU' | 'EN' | 'UK' = 'UK', salesUrl: string): Observable<{ flag_get_url: number, session_id: string, shop_api_key: string, url: string }> {
    return this.httpClient.post<any>(`${API_URL}v1/client/order-buy-bin/${language}`, body, 
      { params: new HttpParams().set('salesChannel', 'WIDJET').set('salesUrl', salesUrl)});
  }

  orderBook(body: { user: User, order: string, comment: string }): Observable<BookedOrderModel> {
    return this.httpClient.post<any>(`${API_URL}v1/client/order-booked-bin`, body);
  }

  getBin(orderId: string): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      if (orderId) {
        return this.httpClient.get<BookedSeatModel[]>(`${API_URL}v1/find-bin-by-order-id/${orderId}`).pipe(catchError((err) => {
          if (err.status === 410) {
            let orders: any[] = JSON.parse(this.commonService.getFromLocalStorage('orders')) || [];
            orders = orders.filter(val => val.order !== orderId);
            if (orders.length) {
              this.commonService.setToLocalStorage('orders', JSON.stringify(orders), true);
            } else {
              this.commonService.removeFromLocalStorage('orders');
            }
            this.translateService.get('basketTimeIsUp').subscribe(text => {
              this.snackBarService.showSnackBar(text);
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          return throwError(err);
        }));
      } else {
        return of([]);
      }
    }
  }

  checkPromoCode(code: string, eventId, order: string) {
    return this.httpClient.post(`${API_URL}${EndPoints.promoCode}/${eventId}`, {}, {
      params: new HttpParams()
        .set('code', code)
        .set('binId', order),
    });
  }

  destroy(id): Observable<any> {
    return this.httpClient.delete(`${API_URL}${EndPoints.destroyBin}/${id}`);
  }


}
