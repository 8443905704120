import { languageReducer } from '@app/+store/language/language.reducer';
import { LanguageModel } from '@app/models/language.model';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../environments/environment';
import { LanguageEffects } from '@app/+store/language/language.effects';
import { bookingReducer } from '@app/+store/booking/booking.reducer';
import { BookingEffects } from '@app/+store/booking/booking.effects';
import { eventReducer } from '@app/+store/event/event.reducer';
import { EventEffects } from '@app/+store/event/event.effects';
import { BookingState } from '@app/+store/booking/booking.state';
import { EventState } from '@app/+store/event/event.state';

export interface IAppState {
  language: LanguageModel;
  booking: BookingState;
  event: EventState;
}

export const reducers: ActionReducerMap<IAppState> = {
  language: languageReducer,
  booking: bookingReducer,
  event: eventReducer,
};

export const EFFECTS = [
  LanguageEffects,
  BookingEffects,
  EventEffects,
];

// console.log all actions
export function logger(reducer: ActionReducer<IAppState>): ActionReducer<any, any> {
  return function (state: IAppState, action: any): IAppState {
    /* tslint:disable */
    // console.log ('action', action);
    /* tslint:enable */
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [logger, storeFreeze]
  : [];
