import { Action } from '@ngrx/store';
import { BookedSeatModel } from '@app/models/booked-seat.model';
import { BookingModel } from '@app/models/booking.model';

export enum BookingActionTypes {
  GetBin = '[Booking] Get Bin',
  GetBinSuccess = '[Booking] Get Bin Success',
  GetBinFail = '[Booking] Get Bin Fail',
  CreateBin = '[Booking] Create Bin',
  CreateBinSuccess = '[Booking] Create Bin Success',
  CreateBinFail = '[Booking] Create Bin Fail',
  Booking = '[Booking] Booking',
  BookingSuccess = '[Booking] Booking Success',
  BookingFail = '[Booking] Booking Fail',
  GetPrice = '[Booking] Get Price',
  GetPriceSuccess = '[Booking] Get Price Success',
  GetPriceFail = '[Booking] Get Price Fail',
}

export class GetBin implements Action {
  readonly type = BookingActionTypes.GetBin;

  constructor(public payload: string = null) {}
}

export class GetBinSuccess implements Action {
  readonly type = BookingActionTypes.GetBinSuccess;

  constructor(public payload: BookedSeatModel[] = null) {
  }
}

export class GetBinFail implements Action {
  readonly type = BookingActionTypes.GetBinFail;

  constructor(public payload: any = null) {
  }
}

export class CreateBin implements Action {
  readonly type = BookingActionTypes.CreateBin;

  constructor(public payload: any = null) {}
}

export class CreateBinSuccess implements Action {
  readonly type = BookingActionTypes.CreateBinSuccess;

  constructor(public payload: any = null) {}
}

export class CreateBinFail implements Action {
  readonly type = BookingActionTypes.CreateBinFail;

  constructor(public payload: any = null) {}
}

export class Booking implements Action {
  readonly type = BookingActionTypes.Booking;

  constructor(public payload: BookingModel = null) {}
}

export class BookingSuccess implements Action {
  readonly type = BookingActionTypes.BookingSuccess;

  constructor(public payload: any = null) {}
}

export class BookingFail implements Action {
  readonly type = BookingActionTypes.BookingFail;

  constructor(public payload: any = null) {}
}

export class GetPrice implements Action {
  readonly type = BookingActionTypes.GetPrice;

  constructor(public payload: any = null) {}
}

export class GetPriceSuccess implements Action {
  readonly type = BookingActionTypes.GetPriceSuccess;

  constructor(public payload: any = null) {}
}

export class GetPriceFail implements Action {
  readonly type = BookingActionTypes.GetPriceFail;

  constructor(public payload: any = null) {}
}



export type BookingActions =
  | GetBin
  | GetBinSuccess
  | GetBinFail
  | CreateBin
  | CreateBinSuccess
  | CreateBinFail
  | Booking
  | BookingSuccess
  | BookingFail
  | GetPrice
  | GetPriceSuccess
  | GetPriceFail;
