import { Pipe, PipeTransform } from '@angular/core';
import { IMAGES_URL } from '../../../environments/environment';
import { DEFAULT_IMG } from '@app/app.settings';

@Pipe({
  name: 'imgUrl',
})
export class ImgUrlPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && value.length > 150) {// TODO: check for base 64
      return value;
    }
    return value ? IMAGES_URL + value : DEFAULT_IMG;
  }

}
