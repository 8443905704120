import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-booking-payment',
  templateUrl: './booking-payment.component.html',
  styleUrls: ['./booking-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingPaymentComponent implements OnInit {
  @Input() price;
  @Input() form: FormGroup;
  @Input() iframeSrc: string;

  constructor() { }

  ngOnInit() {
  }
}
