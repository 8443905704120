import { LanguageModel } from '@app/models/language.model';

export const appLocaleConfig = {
  assets: {
    prefix: './assets/i18n/',
    suffix: '.json',
  },
};

export enum LanguageIds {
  En = 'en',
  Ru = 'ru',
  Ua = 'ua',
}

export const DEFAULT_LANGUAGE: LanguageModel = { id: LanguageIds.Ua } as LanguageModel;
export const languages: LanguageModel[] = [{ id: LanguageIds.Ua }, { id: LanguageIds.Ru }, { id: LanguageIds.En }] as LanguageModel[];

export const DEFAULT_CITY = {};

export const DEFAULT_IMG = '../assets/noimage.png';
export const DEFAULT_PROFILE_IMG = '../assets/icons/camera.svg';

export const PHONE_MASK = ['3', '8', ' ', '(', /\d/, /\d/, /\d/, ')', '-', /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const EMAIL_MASK = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

export const googleApiKey = 'AIzaSyD87GyR7hs0YHrcnt8_NAo82zQTceScoEs';
export const WTFAuthHeader = 'Basic  dXNlci1jbGllbnQ6MTIzNDU2';

export const PASSWORD_PATTERN = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export enum SeatColorsEnum {
  Selected = '#000000',
  Booked = 'rgb(235, 87, 87)', // '#EB5757',
  Disabled = '#B6B6B6',
}

export enum RouterPath {
  Event = '/event',
  Book = '/booking',
  Hall = '/hall',
}
