import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppTranslateService } from '@app/services';
import {
  GET_LANGUAGE,
  GetLanguageSuccess,
  LANGUAGE_CHANGE,
  LanguageChange,
  LanguageChangeSuccess,
} from '@app/+store/language/language.actions';
import { map, switchMap } from 'rxjs/operators';
import { LanguageModel } from '@app/models/language.model';

@Injectable()
export class LanguageEffects {
  @Effect()
  language$ = this.actions$.pipe(
    ofType(LANGUAGE_CHANGE),
    map((action: LanguageChange) => action.payload),
    switchMap( (language: LanguageModel) => this.appTranslateService.setCurrentLocale(language.id, language, true)),
    map((res) => new LanguageChangeSuccess(res)),
  );

  @Effect()
  getLanguage$ = this.actions$.pipe(
    ofType(GET_LANGUAGE),
    switchMap( () => this.appTranslateService.getCurrentLocale()),
    map((res) => new GetLanguageSuccess(res)),
  );

  constructor(private actions$: Actions,
              private appTranslateService: AppTranslateService) { }

}
