import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {distinctUntilChanged, switchMap, tap} from 'rxjs/internal/operators';
import {LanguageModel} from '@app/models/language.model';
import {DEFAULT_LANGUAGE} from '@app/app.settings';
import {CommonService} from '@app/services/common.service';


const SELECTED_LOCALE = 'selected-locale';

@Injectable({
  providedIn: 'root',
})
export class AppTranslateService {
  private storage = localStorage;
  private _currentLocale$$ = new BehaviorSubject<LanguageModel>(({} as LanguageModel));
  currentLocale$: Observable<LanguageModel> = this._currentLocale$$.asObservable().pipe(distinctUntilChanged());

  constructor(private translate: TranslateService,
              private commonService: CommonService,
  ) {
  }

  setCurrentLocale(currentLocale: string, appLocale: LanguageModel, shouldSaveToStorage: boolean = false): Observable<LanguageModel> {
    // if (isPlatformBrowser(this.platformId)) {
    return this.translate.use(currentLocale).pipe(
      switchMap(() => of(appLocale)),
      tap((nextLocale: LanguageModel) => this.onLocaleChanged(nextLocale, shouldSaveToStorage)),
    );
    // }
  }


  getCurrentLocale(): Observable<LanguageModel> {
    // if (isPlatformBrowser(this.platformId)) {
    const currentLocaleFromStorage = this.commonService.parserFromStorage(SELECTED_LOCALE);
    const currentLocale = currentLocaleFromStorage ? currentLocaleFromStorage : DEFAULT_LANGUAGE;
    this.translate.use(currentLocale.id || DEFAULT_LANGUAGE.id);
    this._currentLocale$$.next(currentLocale);
    return this.currentLocale$;
    // }
  }

  private onLocaleChanged(nextLocale: LanguageModel, shouldSaveToStorage: boolean = false): void {
    // if (isPlatformBrowser(this.platformId)) {
    this._currentLocale$$.next(nextLocale);
    if (shouldSaveToStorage) {
      this.storage.setItem(SELECTED_LOCALE, JSON.stringify(nextLocale));
    }
    // }
  }
}
